<div *ngIf="isAuthenticated">
  <fm-header
    [smallLogo]="false"
    [noFixed]="true"
    currentApp="Multifamily Market View"
    [userName]="userName"
    [alignMenuRight]="true"
    [profileMenuItems]="items"
  ></fm-header>
  <fm-menu-bar [iconStyle]="{ 'font-size': '13px' }" [menu]="menu">
  </fm-menu-bar>
  <router-outlet></router-outlet>
</div>
