<h2>Products</h2>
<ul>
  <li
    *ngFor="let product of sideNavMenu"
    (click)="selectItem(product)"
    [class.selected]="selectedItem && product.vw_id === selectedItem.vw_id"
  >
    {{ product.vw_nme }}
  </li>
</ul>
