import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  items: any;
  isAuthenticated = false;
  private authSubscription: Subscription;
  firstName: string = '';
  lastName: string = '';
  roles: string[];
  menu: any[];

  userName = {
    firstName: this.firstName,
    lastName: this.lastName,
  };

  buildMenu(): void {
    this.menu = [];
    if (
      this.roles.includes('SG-NV-ACPT-Trader') ||
      this.roles.includes('SG-NV-PROD-Trader') ||
      this.roles.includes('SG-NV-ACPT-FM_VIEW_ONLY') ||
      this.roles.includes('SG-NV-PROD-FM_VIEW_ONLY')
    ) {
      this.menu.push(
        {
          title: 'Product Listings',
          url: '/screen',
          list: [],
          exactMatch: true,
          command: () => {
            this.router.navigate(['./screen']);
          },
          iconPosition: 'right',
        },
        {
          title: 'Rate Entry',
          url: '/rate-entry',
          list: [],
          exactMatch: true,
          iconPosition: 'right',
        },
        {
          title: 'Admin',
          url: '/admin',
          list: [],
          exactMatch: true,
          iconPosition: 'right',
        }
      );
    } else {
      this.menu.push({
        title: 'Product Listings',
        url: '/screen',
        list: [],
        exactMatch: true,
        command: () => {
          this.router.navigate(['./screen']);
        },
        iconPosition: 'right',
      });
    }
  }

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.items = [
      {
        label: 'Logout',
        icon: 'fmi fmi-sign-out',
        command: () => {
          const envUrls: { [key: string]: string } = {
            devl: 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping?TargetResource=',
            test: 'https://fmsso-test.fanniemae.com/idp/startSLO.ping?TargetResource=',
            acpt: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping?TargetResource=',
            prod: 'https://fmsso-prod.fanniemae.com/idp/startSLO.ping?TargetResource=',
          };

          const origin = window.location.origin;
          const environment = Object.keys(envUrls).find((env) =>
            origin.includes(env)
          );

          if (environment) {
            window.location.replace(
              `${envUrls[environment]}https://www.fanniemae.com`
            );
          }
        },
      },
    ];

    this.authSubscription = this.auth.authStatus$.subscribe({
      next: (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      },
      error: (error) => console.log('Error in auth status subscription', error),
    });

    this.auth.getUserDetails().subscribe({
      next: (data) => {
        this.firstName = data && data.userDetails.firstName;
        this.lastName = data && data.userDetails.lastName;
        this.userName = {
          firstName: this.firstName,
          lastName: this.lastName,
        };
        this.roles = this.auth.getRoles();
        this.buildMenu();
      },
      error: (error) => console.log('Error fetching user details', error),
      complete: () => {
        this.router.navigate(['./screen']);
      },
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
