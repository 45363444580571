import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  constructor(private http: HttpClient) {}

  getScreenData(roles: any, uid: string): Observable<any> {
    if (roles && Array.isArray(roles)) {
      let params = new HttpParams().set('user_id', uid);

      return this.http.get(window.location.origin + '/v1/apis/screen', {
        params,
      });
    } else {
      return throwError(() => new Error('Roles are undefined or not an array'));
    }
  }
}
