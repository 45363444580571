import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RateEntryService {
  constructor(private http: HttpClient) {}

  getRateEntryData(): Observable<any> {
    return this.http.get(window.location.origin + '/v1/apis/rateentry');
  }

  putRateEntryData(uid: string, data: any): Observable<any> {
    const payload = {
      user_id: uid,
      update_rows: data,
    };

    return this.http.put(
      window.location.origin + '/v1/apis/rateentry',
      payload
    );
  }

  putUpdateStatus(data: any): Observable<any> {
    const payload = {
      update_status: data,
    };

    return this.http.put(
      window.location.origin + '/v1/apis/updatestatus',
      payload
    );
  }
}
