export interface Item {
  idx_id: string;
  idx_nme: string;
  idx_rt: string;
}

export interface TableRow {
  prdct_id: string;
  [key: string]: any;
}

export const utils = {
  transformArray(array: Item[]): { [key: string]: string } {
    return array.reduce((acc, item) => {
      acc[item.idx_nme] = item.idx_rt;
      return acc;
    }, {} as { [key: string]: string });
  },

  findDifferences(
    initialValues: TableRow[],
    updatedValues: TableRow[]
  ): { prdct_id: string; [key: string]: any }[] {
    const differences: {
      prdct_id: string;
      [key: string]: any;
    }[] = [];

    updatedValues.forEach((updatedRow, index) => {
      const initialRow = initialValues[index];
      const changedFields: { [key: string]: any } = {};
      for (const key in updatedRow) {
        if (updatedRow[key] !== initialRow[key] && key !== 'changedFields') {
          changedFields[key] = updatedRow[key];
        }
      }

      if (Object.keys(changedFields).length > 0) {
        differences.push({
          prdct_id: updatedRow.prdct_id,
          ...changedFields,
        });
      }
    });
    return differences;
  },
};
