<div *ngIf="isScreen && !userStatusLocked" class="screen">
  <div class="fm-marquee">
    <fm-marquee title="Announcements" [speed]="marqueeSpeed">
      {{ announcements }}
    </fm-marquee>
  </div>

  <div class="container">
    <app-side-nav
      [sideNavMenu]="sideNavMenu"
      (itemSelected)="displayContent($event)"
    ></app-side-nav>
    <app-content
      [baseIndexRates]="baseIndexRates"
      [currentProductListing]="currentProductListing"
      [currentAssumptionsForFixedRate]="currentAssumptionsForFixedRate"
    ></app-content>
  </div>

  <div class="footer">
    <fm-footer
      ><p>Please call the Multifamily Desk at 888-889-1118.</p>
      <br />
      <p>
        The above spread indications, which are for informational purposes only,
        are subject to change with market conditions. These spreads are not firm
        bids nor offers for any of these structures. Fannie Mae makes no
        representations or warranties that these spreads are accurate, and
        should not be relied upon as such.
      </p></fm-footer
    >
  </div>
</div>

<div *ngIf="!isScreen" class="container">
  <div class="card">
    <h2 class="card-title">No Products Available</h2>
    <br />
    <p class="card-body">
      You currently do not have access to any products. Please contact the
      Multifamily Desk at 888-889-1118.
    </p>
    <br />
    <p class="card-body">Sorry for the inconvenience</p>
  </div>
</div>

<div *ngIf="userStatusLocked" class="container">
  <div class="card">
    <h2 class="card-title">View is Temporarily Unavailable</h2>
    <br />
    <p class="card-body">
      Access to listings is temporarily unavailale while today's rates are being
      updated. Please check back later.
    </p>
    <br />
    <p class="card-body">
      <b
        >If you have any questions or concerns, please call the Multifamily Desk
        at 888-889-1118.</b
      >
    </p>
  </div>
</div>

<fm-loading-spinner [isLoading]="isLoading"></fm-loading-spinner>
