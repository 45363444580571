import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { RateEntryService } from 'src/app/services/rate-entry.service';
import { TableRow, utils } from '../../utils/utils';
import { ConfirmationService } from '@fm-ui-adk/components-3/common/services';

export interface RowData {
  changedFields?: { [key: string]: boolean };
  [key: string]: any;
}
@Component({
  selector: 'app-rate-entry',
  templateUrl: './rate-entry.component.html',
  styleUrls: ['./rate-entry.component.scss'],
})
export class RateEntryComponent {
  announcements: string = '';
  baseIndexRates: { idx_id: string; idx_nme: string; idx_rt: string }[] = [];
  cols: any;
  globalFilterBinding: string;
  people: any;
  swapSpreads: { id: string; name: string; spread: string }[] = [];
  initialBaseIndexRates: { idx_id: string; idx_nme: string; idx_rt: string }[] =
    [];
  initialSwapSpreads: { id: string; name: string; spread: string }[] = [];
  products: object[] = [];
  changedFields: { [key: string]: boolean } = {};
  isLoading: boolean = true;
  uid: string;
  initialTableValues: TableRow[];
  @ViewChild('tbl') tableElement: any;
  updatedTableValues: TableRow[];
  form: string;
  alert: any = [];
  roles: string[] = [];
  isAdmin: boolean;
  userStatus: boolean;

  inputBindings: { [key: string]: string } = {
    term: '',
    vw_nme: '',
    yld_maint: '',
    idx_nme: '',
    beg_mkt_sprd: '',
    end_mkt_sprd: '',
    begng_endng_sprd: '',
    begng_reqd_netsprd: '',
    endng_reqd_netsprd: '',
    begng_reqd_netyld: '',
    endng_reqd_netyld: '',
  };

  constructor(
    private rateEntryService: RateEntryService,
    private authService: AuthService,
    private confirmationService: ConfirmationService
  ) {}
  ngOnInit() {
    this.roles = this.authService.getRoles();
    this.uid = this.authService.getUid();
    this.isAdmin =
      this.roles.includes('SG-NV-ACPT-Trader') ||
      this.roles.includes('SG-NV-PROD-Trader');
    this.cols = [
      { field: 'term', header: 'Term' },
      { field: 'vw_nme', header: 'View' },
      { field: 'yld_maint', header: 'Yield Maintenance (Years)' },
      { field: 'idx_nme', header: 'Index' },
      {
        field: 'beg_mkt_sprd',
        header: 'Market Spread Minimum',
      },
      {
        field: 'end_mkt_sprd',
        header: 'Market Spread Maximum',
      },
      { field: 'begng_endng_sprd', header: 'Spread' },
      {
        field: 'begng_reqd_netsprd',
        header: 'Required Net Spread Mininum',
      },
      {
        field: 'endng_reqd_netsprd',
        header: 'Required Net Spread Maximum',
      },
      {
        field: 'begng_reqd_netyld',
        header: 'Required Net Yield Minimum',
      },
      {
        field: 'endng_reqd_netyld',
        header: 'Required Net Yield Maximum',
      },
    ];
    this.handleGetRateEntry();
  }

  handleGetRateEntry(): void {
    this.rateEntryService.getRateEntryData().subscribe({
      next: (data) => {
        this.announcements = data && data.comment_data.cmnt;
        this.baseIndexRates = data && data.rate_base_index;
        this.initialBaseIndexRates = JSON.parse(
          JSON.stringify(data && data.rate_base_index)
        );
        this.swapSpreads = data && data.swap_spread;
        this.initialSwapSpreads = JSON.parse(
          JSON.stringify(data && data.swap_spread)
        );

        this.products = data && data.product_view_data;
        this.initialTableValues = JSON.parse(JSON.stringify(this.products));
        this.userStatus = data && data.user_status === 'D' ? false : true;
      },
      error: (err) => console.error('error on rate-entry api:', err),
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleConfirmation(form: string): void {
    this.form = form;
    this.showDialog();
  }

  handleSubmit(form: string): void {
    if (form === 'announcements') {
      this.updateRateEntry([{ cmnt: this.announcements }]);
    } else if (form === 'baseIndexes') {
      const modifiedBaseRateIndexs = this.getModifiedBaseRateIndexes();
      this.updateRateEntry(modifiedBaseRateIndexs);
    } else if (form === 'swapSpreads') {
      const modifiedSwapSpreads = this.getModifiedSwapSpreads();
      this.updateRateEntry(modifiedSwapSpreads);
    } else {
      this.handleProducts();
    }
  }

  getModifiedBaseRateIndexes(): any[] {
    return this.baseIndexRates
      .filter(
        (baseIndexrate, index) =>
          baseIndexrate.idx_rt !== this.initialBaseIndexRates[index].idx_rt
      )
      .map(({ idx_id, idx_rt }) => ({ idx_id, idx_rt }));
  }

  getModifiedSwapSpreads(): any[] {
    return this.swapSpreads
      .filter(
        (swapSpread, index) =>
          swapSpread.spread !== this.initialSwapSpreads[index].spread
      )
      .map(({ id, spread }) => ({ id, spread }));
  }

  updateRateEntry(data: any[] | string): void {
    this.rateEntryService.putRateEntryData(this.uid, data).subscribe({
      next: () => {
        this.handleGetRateEntry();
        this.handleSuccess('success');
      },
      error: () => this.handleError('error'),
    });
  }

  handleSuccess(type: any): void {
    this.alert = [];
    this.alert.push({ type: type, message: type });
  }

  handleError(type: any): void {
    this.alert = [];
    this.alert.push({ type: type, message: type });
  }

  handleProducts() {
    this.updatedTableValues = this.tableElement.value;
    const modifiedProductsTableValues = utils.findDifferences(
      this.initialTableValues,
      this.updatedTableValues
    );
    this.updateRateEntry(modifiedProductsTableValues);
  }

  onValueChange(rowData: RowData, field: string) {
    if (!rowData.changedFields) {
      rowData.changedFields = {};
    }
    rowData.changedFields[field] = true;
  }

  showDialog() {
    this.confirmationService.confirm({
      header: 'Are you sure you want to update these values?',
      message:
        'Click "Confirm" to submit the updated data values in the table and they will be updated for the corresponding records',
      accept: () => {
        this.handleSubmit(this.form);
      },
      reject: () => {
        console.log('Rejected');
      },
      key: 'confirm',
    });
  }

  handleUpdateStatus(userStatus: boolean): void {
    const data = userStatus === false ? 'D' : 'E';
    this.rateEntryService.putUpdateStatus(data).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  validateDecimal(event: any): void {
    const value = event.target.value;
    const regex = /^-?\d+(\.\d{0,4})?$/;
    if (!regex.test(value)) {
      if (value !== '-') {
      		event.target.value = value.slice(0, -1);
      }
    }
  }

  handleClear(): void {
    this.handleGetRateEntry();
  }
}
