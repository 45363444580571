import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScreenComponent } from './modules/screen/screen.component';
import { AuthGuard } from './services/auth.guard';
import { RateEntryComponent } from './modules/rate-entry/rate-entry.component';
import { AdminComponent } from './modules/admin/admin.component';

const routes: Routes = [
  { path: '', redirectTo: '/screen', pathMatch: 'full' },
  {
    path: 'screen',
    component: ScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'rate-entry',
    component: RateEntryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/screen' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
