import { Component } from '@angular/core';
import { ScreenService } from 'src/app/services/screen.service';
import { utils } from '../../utils/utils';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss'],
})
export class ScreenComponent {
  sideNavMenu: object[] = [];
  announcements: string = '';
  baseIndexRates: any;
  currentProductListingId: string;
  productListing: object[] = [];
  selectedItemId: string;
  firstsideNavMenu: string;
  currentProductListing: any = [];
  currentAssumptionsForFixedRate: string;
  marqueeSpeed: number = 60;
  isLoading: boolean = true;
  roles: any;
  uid: string;
  isScreen: boolean = true;
  userStatusLocked: boolean = false;

  constructor(
    private screenService: ScreenService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.roles = this.authService.getRoles();
    this.uid = this.authService.getUid();
    this.screenService.getScreenData(this.roles, this.uid).subscribe({
      next: (data) => {
        if (data?.message === 'user is locked') {
          this.userStatusLocked = true;
          return;
        } else if (data?.message === 'No data found') {
          this.isScreen = false;
          this.userStatusLocked = false;
          return;
        } else {
          this.userStatusLocked = false;
          this.sideNavMenu = data.list_view_data;
          this.announcements = data.comment_data.cmnt;
          this.baseIndexRates =
            data && utils.transformArray(data.rate_base_index);
          this.productListing = data.product_view_data;
          this.currentProductListingId = data.list_view_data[0].vw_id;
          this.currentProductListing = data.product_view_data.filter(
            (product: any) => product.vw_id === this.currentProductListingId
          );
          this.currentAssumptionsForFixedRate =
            (this.currentProductListing[0] &&
              this.currentProductListing[0].relatedcontent) ||
            '';
        }
      },
      error: (err) => console.error('error on screen api:', err),
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  displayContent(selectedItem: any) {
    this.selectedItemId = selectedItem.vw_id;
    this.currentProductListing = this.productListing.filter(
      (product: any) => product.vw_id === this.selectedItemId
    );
    console.log('currentProductListing', this.currentProductListing);
    this.currentAssumptionsForFixedRate =
      (this.currentProductListing[0] &&
        this.currentProductListing[0].relatedcontent) ||
      '';
  }
}
