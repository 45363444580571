<fm-alert-feedback [alignLeft]="false" [alert]="alert"></fm-alert-feedback>
<div class="container">
  <div *ngIf="isAdmin" style="float: right; margin-bottom: -50px">
    <h4>External User Access</h4>
    <fm-input-switch
      leftLabel="OFF"
      rightLabel="ON"
      [(ngModel)]="userStatus"
      (onChange)="handleUpdateStatus(userStatus)"
    ></fm-input-switch>
  </div>
  <div class="flex-parent-element">
    <div class="flex-child-element" [ngClass]="{ 'half-width': isCardVisible }">
      <fm-tabView (onChange)="handleTabChange($event)">
        <fm-tabPanel header="Organizations">
          <div *ngIf="isAdmin">
            <button
              fmButton
              [primary]="true"
              type="button"
              label="Add Organization"
              style="float: right"
              (click)="onAdd()"
            ></button>
            <br />
            <br />
          </div>
          <fm-table
            #orgtbl
            [columns]="orgCols"
            [value]="organizations"
            [enableTableEntryTotal]="true"
            [paginator]="true"
            [rows]="10"
            [rowsPerPageOptions]="[10, 100, 1000]"
          >
            <ng-template fmTemplate="caption">
              <div style="text-align: right">
                <input
                  placeholder="Global Filter"
                  style="width: auto"
                  fmTextInput
                  type="text"
                  [(ngModel)]="orgGlobalFilterBinding"
                  (input)="
                    orgtbl.filterGlobal($any($event.target).value, 'contains')
                  "
                />
              </div>
            </ng-template>
            <ng-template fmTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [style.width]="col.width">
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" [style.width]="col.width">
                  <ng-container
                    *ngIf="col.field === 'modify'; else checkDelete"
                  >
                    <i
                      class="fmi fmi-edit"
                      aria-hidden="true"
                      (click)="onModify(rowData)"
                      [ngClass]="{ 'disabled-icon': !isAdmin }"
                    ></i>
                  </ng-container>
                  <ng-template #checkDelete>
                    <ng-container *ngIf="col.field === 'delete'; else showData">
                      <i
                        class="fmi fmi-trash-o"
                        aria-hidden="true"
                        (click)="handleDeleteConfirmation(rowData.org_id)"
                        [ngClass]="{ 'disabled-icon': !isAdmin }"
                      ></i>
                    </ng-container>
                  </ng-template>
                  <ng-template #showData>
                    <a (click)="showCard(rowData)">{{ rowData[col.field] }}</a>
                  </ng-template>
                </td>
              </tr>
            </ng-template>
          </fm-table></fm-tabPanel
        >
        <fm-tabPanel header="Views">
          <div *ngIf="isAdmin">
            <button
              fmButton
              [primary]="true"
              type="button"
              label="Add View"
              style="float: right"
              (click)="onAdd()"
            ></button>
            <br />
            <br />
          </div>
          <fm-table
            #viewtbl
            [columns]="viewCols"
            [value]="views"
            [enableTableEntryTotal]="true"
            [paginator]="true"
            [rows]="10"
            [rowsPerPageOptions]="[10, 100, 1000]"
          >
            <ng-template fmTemplate="caption">
              <div style="text-align: right">
                <input
                  placeholder="Global Filter"
                  style="width: auto"
                  fmTextInput
                  type="text"
                  [(ngModel)]="viewGlobalFilterBinding"
                  (input)="
                    viewtbl.filterGlobal($any($event.target).value, 'contains')
                  "
                />
              </div>
            </ng-template>
            <ng-template fmTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [style.width]="col.width">
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" [style.width]="col.width">
                  <ng-container
                    *ngIf="col.field === 'modify'; else checkDelete"
                  >
                    <i
                      class="fmi fmi-edit"
                      aria-hidden="true"
                      (click)="onModify(rowData)"
                      [ngClass]="{ 'disabled-icon': !isAdmin }"
                    ></i>
                  </ng-container>
                  <ng-template #checkDelete>
                    <ng-container *ngIf="col.field === 'delete'; else showData">
                      <i
                        class="fmi fmi-trash-o"
                        aria-hidden="true"
                        (click)="handleDeleteConfirmation(rowData.vw_id)"
                        [ngClass]="{ 'disabled-icon': !isAdmin }"
                      ></i>
                    </ng-container>
                  </ng-template>
                  <ng-template #showData>
                    <a (click)="showCard(rowData)">{{ rowData[col.field] }}</a>
                  </ng-template>
                </td>
              </tr>
            </ng-template>
          </fm-table>
        </fm-tabPanel>

        <fm-tabPanel header="Products">
          <div *ngIf="isAdmin">
            <button
              fmButton
              [primary]="true"
              type="button"
              label="Add Product"
              style="float: right"
              (click)="onAdd()"
            ></button>
            <br />
            <br />
          </div>
          <fm-table
            #producttbl
            [columns]="productCols"
            [value]="products"
            [enableTableEntryTotal]="true"
            [paginator]="true"
            [rows]="10"
            [rowsPerPageOptions]="[10, 100, 1000]"
          >
            <ng-template fmTemplate="caption">
              <div style="text-align: right">
                <input
                  placeholder="Global Filter"
                  style="width: auto"
                  fmTextInput
                  type="text"
                  [(ngModel)]="productGlobalFilterBinding"
                  (input)="
                    producttbl.filterGlobal(
                      $any($event.target).value,
                      'contains'
                    )
                  "
                />
              </div>
            </ng-template>
            <ng-template fmTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [style.width]="col.width">
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" [style.width]="col.width">
                  <ng-container
                    *ngIf="col.field === 'modify'; else checkDelete"
                  >
                    <i
                      class="fmi fmi-edit"
                      aria-hidden="true"
                      (click)="onModify(rowData)"
                      [ngClass]="{ 'disabled-icon': !isAdmin }"
                    ></i>
                  </ng-container>
                  <ng-template #checkDelete>
                    <ng-container *ngIf="col.field === 'delete'; else showData">
                      <i
                        class="fmi fmi-trash-o"
                        aria-hidden="true"
                        (click)="handleDeleteConfirmation(rowData.product_id)"
                        [ngClass]="{ 'disabled-icon': !isAdmin }"
                      ></i>
                    </ng-container>
                  </ng-template>
                  <ng-template #showData>
                    <a
                      *ngIf="col.field === 'term'"
                      (click)="showCard(rowData)"
                      >{{ rowData[col.field] }}</a
                    >
                    <div
                      *ngIf="col.field !== 'term'"
                      (click)="showCard(rowData)"
                    >
                      {{ rowData[col.field] }}
                    </div>
                  </ng-template>
                </td>
              </tr>
            </ng-template>
          </fm-table>
        </fm-tabPanel>

        <fm-tabPanel header="Users">
          <fm-table
            #usertbl
            [columns]="userCols"
            [value]="users"
            [enableTableEntryTotal]="true"
            [paginator]="true"
            [rows]="10"
            [rowsPerPageOptions]="[10, 100, 1000]"
          >
            <ng-template fmTemplate="caption">
              <div style="text-align: right">
                <input
                  placeholder="Global Filter"
                  style="width: auto"
                  fmTextInput
                  type="text"
                  [(ngModel)]="userGlobalFilterBinding"
                  (input)="
                    usertbl.filterGlobal($any($event.target).value, 'contains')
                  "
                />
              </div>
            </ng-template>
            <ng-template fmTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [style.width]="col.width">
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" [style.width]="col.width">
                  <ng-container *ngIf="col.field === 'modify'; else showData">
                    <i
                      class="fmi fmi-edit"
                      aria-hidden="true"
                      (click)="onModify(rowData)"
                      [ngClass]="{ 'disabled-icon': !isAdmin }"
                    ></i>
                  </ng-container>
                  <ng-template #showData>
                    <a (click)="showCard(rowData)">{{ rowData[col.field] }}</a>
                  </ng-template>
                </td>
              </tr>
            </ng-template>
          </fm-table></fm-tabPanel
        >
      </fm-tabView>
    </div>

    <div
      *ngIf="isCardVisible && isOrganization"
      class="card flex-child-element"
      [ngClass]="isCardVisible ? 'details-card-visible' : 'details-card-hidden'"
    >
      <h2 class="card-title">Organization Information</h2>
      <br />
      <div>
        <div *ngIf="isReadMode">
          <div class="label-value-container">
            <p>ORGANIZATION NAME</p>
            <p>{{ selectedOrg.org_name }}</p>
          </div>
          <hr />
          <div class="label-value-container">
            <p>VIEW<span class="spacer"></span></p>
            <p>{{ selectedOrg.views.join(",") }}</p>
          </div>
        </div>
        <div *ngIf="isEditMode">
          <div>
            <h4>Organization</h4>
            <input type="text" fmTextInput [(ngModel)]="selectedOrg.org_name" />
            <br />
            <br />
            <h4>View</h4>
            <fm-multiselect
              ariaLabelledBy="searchMultiselect"
              optionLabel="viewLabel"
              valueLabel="viewValue"
              [options]="allViewsForOrg"
              [(ngModel)]="selectedViewsForDropdown"
              [filter]="true"
              [maxSelectedLabels]="3"
            >
            </fm-multiselect>
          </div>
        </div>
        <div *ngIf="isAddMode">
          <div>
            <h4>Organization</h4>
            <input type="text" fmTextInput [(ngModel)]="selectedOrg.org_name" />
            <div *ngIf="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
            <br />
            <h4>View</h4>
            <fm-multiselect
              ariaLabelledBy="searchMultiselect"
              optionLabel="viewLabel"
              valueLabel="viewValue"
              [options]="allViewsForOrg"
              [(ngModel)]="selectedViewsForDropdown"
              [filter]="true"
              [maxSelectedLabels]="3"
            >
            </fm-multiselect>
          </div>
        </div>
        <br />
        <div class="button-container">
          <button
            fmButton
            type="button"
            label="Close"
            (click)="closeCard()"
          ></button>
          <button
            *ngIf="isReadMode"
            fmButton
            type="button"
            label="Delete"
            (click)="handleDeleteConfirmation(selectedOrg.org_id)"
            [disabled]="!isAdmin"
          ></button>
          <button
            *ngIf="!isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Edit"
            (click)="onModify(selectedOrg)"
            [disabled]="!isAdmin"
          ></button>
          <button
            *ngIf="isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Submit"
            (click)="handleConfirmation()"
            [disabled]="!checkSelectedOrg()"
          ></button>
        </div>
      </div>
    </div>

    <div
      *ngIf="isCardVisible && isView"
      class="card flex-child-element"
      [ngClass]="isCardVisible ? 'details-card-visible' : 'details-card-hidden'"
    >
      <h2 class="card-title">View Information</h2>
      <br />
      <div>
        <div *ngIf="isReadMode">
          <div class="label-value-container">
            <p>VIEW NAME</p>
            <p>{{ selectedView.vw_name }}</p>
          </div>
          <hr />
          <div class="label-value-container">
            <p>VIEW TITLE</p>
            <p>{{ selectedView.vw_title }}</p>
          </div>
          <hr />
          <div class="label-value-container">
            <p>RELATED CONTENT<span class="spacer"></span></p>
            <div [innerHTML]="selectedView.related_content"></div>
          </div>
        </div>
        <div *ngIf="isEditMode">
          <div>
            <h4>View Name:</h4>
            <input type="text" fmTextInput [(ngModel)]="selectedView.vw_name" />
            <br />
            <br />
            <h4>View Title:</h4>
            <input
              type="text"
              fmTextInput
              [(ngModel)]="selectedView.vw_title"
            />
            <br />
            <br />
            <h4>Related Content</h4>
            <fm-editor
              [(ngModel)]="selectedView.related_content"
              [style]="&#123;'height':'320px'&#125;"
            ></fm-editor>
          </div>
        </div>
        <div *ngIf="isAddMode">
          <div>
            <h4>View Name:</h4>
            <input type="text" fmTextInput [(ngModel)]="selectedView.vw_name" />
            <div *ngIf="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
            <br />
            <h4>View Title:</h4>
            <input
              type="text"
              fmTextInput
              [(ngModel)]="selectedView.vw_title"
            />
            <br />
            <br />
            <h4>Related Content</h4>
            <fm-editor
              [(ngModel)]="selectedView.related_content"
              [style]="&#123;'height':'320px'&#125;"
              placeholder="Start Typing Here..."
            >
            </fm-editor>
          </div>
        </div>
        <br />
        <div class="button-container">
          <button
            fmButton
            type="button"
            label="Close"
            (click)="closeCard()"
          ></button>
          <button
            *ngIf="isReadMode"
            fmButton
            type="button"
            label="Delete"
            (click)="handleDeleteConfirmation(selectedView.vw_id)"
            [disabled]="!isAdmin"
          ></button>
          <button
            *ngIf="!isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Edit"
            (click)="onModify(selectedView)"
            [disabled]="!isAdmin"
          ></button>
          <button
            *ngIf="isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Submit"
            (click)="handleConfirmation()"
            [disabled]="!checkSelectedView()"
          ></button>
        </div>
      </div>
    </div>

    <div
      *ngIf="isCardVisible && isProduct"
      class="card flex-child-element"
      [ngClass]="isCardVisible ? 'details-card-visible' : 'details-card-hidden'"
    >
      <h2 class="card-title">Product Information</h2>
      <br />
      <div>
        <div *ngIf="isReadMode">
          <fm-dataList
            [value]="selectedProduct"
            [isCompressed]="true"
            [exclude]="['product_id']"
            [rename]="{
              cvt: 'Convertible Flag',
              core_ind: 'Core Indicator',
              yld_maint: 'Yield Maintenance'
            }"
          ></fm-dataList>
        </div>
        <div *ngIf="isEditMode">
          <div>
            <h4>Term</h4>
            <input
              type="number"
              min="1"
              step="1"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              fmTextInput
              [(ngModel)]="selectedProduct.term"
            />
            <br />
            <br />
            <h4>View</h4>
            <fm-select
              ariaLabelledBy="default"
              [options]="allViewsForProducts"
              [(ngModel)]="selectedProduct.view"
            >
            </fm-select>
            <br />
            <br />
            <h4>Yield Maintenance</h4>
            <input
              type="text"
              fmTextInput
              [(ngModel)]="selectedProduct.yld_maint"
            />
            <br />
            <br />
            <h4>Index</h4>
            <fm-select
              ariaLabelledBy="default"
              [options]="allIndexForProducts"
              [(ngModel)]="selectedProduct.index"
            >
            </fm-select>
            <br />
            <br />
            <h4>Gfee</h4>
            <input type="text" fmTextInput [(ngModel)]="selectedProduct.gfee" />
            <br />
            <br />
            <h4>Convertible Flag</h4>
            <div class="ui-g" style="width: 250px; margin-bottom: 10px">
              <div class="ui-g-12">
                <fm-radio
                  name="cvtRadio"
                  value="Y"
                  label="Yes"
                  [(ngModel)]="selectedProduct.cvt"
                  inputId="opt1"
                ></fm-radio>
              </div>
              <div class="ui-g-12">
                <fm-radio
                  name="cvtRadio"
                  value="N"
                  label="No"
                  [(ngModel)]="selectedProduct.cvt"
                  inputId="opt2"
                ></fm-radio>
              </div>
              <div class="ui-g-12">
                <fm-radio
                  name="cvtRadio"
                  value="Neither"
                  label="Neither"
                  [(ngModel)]="selectedProduct.cvt"
                  inputId="opt3"
                ></fm-radio>
              </div>
            </div>
            <br />
            <h4>Core Indicator</h4>
            <div class="ui-g" style="width: 250px; margin-bottom: 10px">
              <div class="ui-g-12">
                <fm-radio
                  name="coreIndRadio"
                  value="Y"
                  label="Yes"
                  [(ngModel)]="selectedProduct.core_ind"
                  inputId="opt1"
                ></fm-radio>
              </div>
              <div class="ui-g-12">
                <fm-radio
                  name="coreIndRadio"
                  value="N"
                  label="No"
                  [(ngModel)]="selectedProduct.core_ind"
                  inputId="opt2"
                ></fm-radio>
              </div>
            </div>
            <br />
            <h4>Description</h4>
            <textarea
              fmTextArea
              [(ngModel)]="selectedProduct.description"
              [autoResize]="true"
              [rows]="10"
              [cols]="20"
            ></textarea>
            <br />
            <br />
          </div>
        </div>
        <div *ngIf="isAddMode">
          <div>
            <h4>Term</h4>
            <input
              type="number"
              min="1"
              step="1"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              fmTextInput
              [(ngModel)]="selectedProduct.term"
            />
            <br />
            <br />
            <h4>View</h4>
            <fm-select
              ariaLabelledBy="default"
              [options]="allViewsForProducts"
              [(ngModel)]="selectedProduct.view"
              placeholder="Choose"
            >
            </fm-select>
            <br />
            <br />
            <h4>Yield Maintenance</h4>
            <input
              type="text"
              fmTextInput
              [(ngModel)]="selectedProduct.yld_maint"
            />
            <br />
            <br />
            <h4>Index</h4>
            <fm-select
              ariaLabelledBy="default"
              [options]="allIndexForProducts"
              [(ngModel)]="selectedProduct.index"
              placeholder="Choose"
            >
            </fm-select>
            <br />
            <br />
            <h4>Convertible Flag</h4>
            <div class="ui-g" style="width: 250px; margin-bottom: 10px">
              <div class="ui-g-12">
                <fm-radio
                  name="cvtRadio"
                  value="Y"
                  label="Yes"
                  [(ngModel)]="selectedProduct.cvt"
                  inputId="opt1"
                ></fm-radio>
              </div>
              <div class="ui-g-12">
                <fm-radio
                  name="cvtRadio"
                  value="N"
                  label="No"
                  [(ngModel)]="selectedProduct.cvt"
                  inputId="opt2"
                ></fm-radio>
              </div>
              <div class="ui-g-12">
                <fm-radio
                  name="cvtRadio"
                  value="Neither"
                  label="Neither"
                  [(ngModel)]="selectedProduct.cvt"
                  inputId="opt3"
                ></fm-radio>
              </div>
            </div>
            <br />
            <h4>Core Indicator</h4>
            <div class="ui-g" style="width: 250px; margin-bottom: 10px">
              <div class="ui-g-12">
                <fm-radio
                  name="coreIndRadio"
                  value="Y"
                  label="Yes"
                  [(ngModel)]="selectedProduct.core_ind"
                  inputId="opt1"
                ></fm-radio>
              </div>
              <div class="ui-g-12">
                <fm-radio
                  name="coreIndRadio"
                  value="N"
                  label="No"
                  [(ngModel)]="selectedProduct.core_ind"
                  inputId="opt2"
                ></fm-radio>
              </div>
            </div>
            <br />
            <h4>Description</h4>
            <textarea
              fmTextArea
              [(ngModel)]="selectedProduct.description"
              [autoResize]="true"
              [rows]="10"
              [cols]="20"
            ></textarea>
            <br />
            <br />
          </div>
        </div>
        <br />
        <div class="button-container">
          <button
            fmButton
            type="button"
            label="Close"
            (click)="closeCard()"
          ></button>
          <button
            fmButton
            *ngIf="isReadMode"
            type="button"
            label="Delete"
            (click)="handleDeleteConfirmation(selectedProduct.product_id)"
            [disabled]="!isAdmin"
          ></button>
          <button
            *ngIf="!isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Edit"
            (click)="onModify(selectedProduct)"
            [disabled]="!isAdmin"
          ></button>
          <button
            *ngIf="isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Submit"
            (click)="handleConfirmation()"
            [disabled]="!checkSelectedProduct()"
          ></button>
        </div>
      </div>
    </div>

    <div
      *ngIf="isCardVisible && isUser"
      class="card flex-child-element"
      [ngClass]="isCardVisible ? 'details-card-visible' : 'details-card-hidden'"
    >
      <h2 class="card-title">{{ selectedUser.full_name }}</h2>
      <br />
      <div>
        <div *ngIf="isReadMode">
          <fm-dataList
            [value]="selectedUser"
            [isCompressed]="true"
            [exclude]="['user_id', 'full_name']"
            [rename]="{
              org: 'Organization',
            }"
          ></fm-dataList>
        </div>
        <div *ngIf="isEditMode">
          <h4>Organization</h4>
          <fm-select
            ariaLabelledBy="default"
            [options]="allOrganizationsForUsers"
            [(ngModel)]="selectedUser.org"
            placeholder="Choose"
          >
          </fm-select>
          <fm-dataList
            [value]="selectedUser"
            [isCompressed]="true"
            [exclude]="['user_id', 'org']"
            [rename]="{
              full_name: 'Full Name'
            }"
          ></fm-dataList>
        </div>
        <br />
        <div class="button-container">
          <button
            fmButton
            type="button"
            label="Close"
            (click)="closeCard()"
          ></button>
          <button
            *ngIf="!isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Edit"
            (click)="onModify(selectedUser)"
            [disabled]="!isAdmin"
          ></button>
          <button
            *ngIf="isSubmit"
            fmButton
            [primary]="true"
            type="button"
            label="Submit"
            (click)="handleConfirmation()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>

<fm-confirmDialog
  acceptLabel="Confirm"
  key="confirm"
  [alignLeft]="true"
></fm-confirmDialog>

<fm-loading-spinner [isLoading]="isLoading"></fm-loading-spinner>
