import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authStatus = new BehaviorSubject<boolean>(false);
  authStatus$ = this.authStatus.asObservable();

  private roles: any;
  private uid: string;

  isAuthenticated(): boolean {
    return this.authStatus.value;
  }

  constructor(private http: HttpClient, private router: Router) {}

  getUserDetails() {
    const url = window.location.origin + '/userdetails';

    return this.http.get(url).pipe(
      tap((userdetails: any) => {
        if (userdetails) {
          this.authStatus.next(true);
          this.roles = userdetails.userDetails.groups;
          this.uid = userdetails.userDetails.uid;
        }
      }),
      catchError((error) => {
        console.error('Error fetching user details', error);
        window.location.href =
          window.location.origin + '/api/?cdxRedirectPath=/';
        return of(null);
      })
    );
  }

  getRoles(): any {
    return this.roles;
  }

  getUid(): string {
    return this.uid;
  }
}
