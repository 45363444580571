<fm-alert-feedback [alignLeft]="false" [alert]="alert"></fm-alert-feedback>
<div class="container">
  <div *ngIf="isAdmin" style="float: right">
    <h4>External User Access</h4>
    <fm-input-switch
      leftLabel="OFF"
      rightLabel="ON"
      [(ngModel)]="userStatus"
      (onChange)="handleUpdateStatus(userStatus)"
    ></fm-input-switch>
  </div>
  <fm-tabView (onChange)="handleGetRateEntry()">
    <fm-tabPanel header="Announcements">
      <div class="announcements">
        <h3>Announcements</h3>
        <br />
        <form (ngSubmit)="handleConfirmation('announcements')">
          <textarea
            fmTextArea
            [(ngModel)]="announcements"
            placeholder="Enter announcements here"
            [rows]="3"
            name="announcements"
            [disabled]="!isAdmin"
          ></textarea>
          <div style="float: right">
            <button
              fmButton
              type="button"
              label="Clear"
              (click)="handleClear()"
            ></button>
            <button
              style="margin-left: 10px"
              fmButton
              [primary]="true"
              type="submit"
              label="Submit"
              [disabled]="!isAdmin"
            ></button>
          </div>
        </form>
      </div>
    </fm-tabPanel>
    <fm-tabPanel header="Base Index">
      <div class="base-index">
        <h3>Base Index Rates</h3>
        <br />
        <form (ngSubmit)="handleConfirmation('baseIndexes')">
          <div class="input-row">
            <div
              class="input-group"
              *ngFor="let baseIndexRate of baseIndexRates; let i = index"
            >
              <label [for]="baseIndexRate.idx_nme">{{
                baseIndexRate.idx_nme
              }}</label>
              <input
                type="text"
                [id]="baseIndexRate.idx_id"
                [(ngModel)]="baseIndexRate.idx_rt"
                [name]="baseIndexRate.idx_nme"
                fmTextInput
                [disabled]="!isAdmin"
                (input)="validateDecimal($event)"
                [ngClass]="{
                  changed:
                    baseIndexRate.idx_rt !== initialBaseIndexRates[i].idx_rt
                }"
              />
            </div>
          </div>
          <br />
          <div style="float: right">
            <button
              fmButton
              type="button"
              label="Clear"
              (click)="handleClear()"
            ></button>
            <button
              style="margin-left: 10px"
              fmButton
              [primary]="true"
              type="submit"
              label="Submit"
              [disabled]="!isAdmin"
            ></button>
          </div>
        </form>
        <br />
      </div>
    </fm-tabPanel>
    <fm-tabPanel header="Swap Spread">
      <div class="swap-spread">
        <h3>Swap Spread</h3>
        <br />
        <form (ngSubmit)="handleConfirmation('swapSpreads')">
          <div class="input-row">
            <div
              class="input-group"
              *ngFor="let swapSpread of swapSpreads; let i = index"
            >
              <label [for]="swapSpread.id">{{ swapSpread.name }}</label>
              <input
                type="number"
                [id]="swapSpread.id"
                [(ngModel)]="swapSpread.spread"
                [name]="swapSpread.id"
                fmTextInput
                [disabled]="!isAdmin"
                step="1"
                onkeypress="return event.charCode >= 48 || event.charCode == 45 && event.charCode <= 57"
                [ngClass]="{
                  changed: swapSpread.spread !== initialSwapSpreads[i].spread
                }"
              />
            </div>
          </div>
          <br />
          <div style="float: right">
            <button
              fmButton
              type="button"
              label="Clear"
              (click)="handleClear()"
            ></button>
            <button
              style="margin-left: 10px"
              fmButton
              [primary]="true"
              type="submit"
              label="Submit"
              [disabled]="!isAdmin"
            ></button>
          </div>
        </form>
        <br />
      </div>
    </fm-tabPanel>
    <fm-tabPanel header="Products">
      <div class="product-header">
        <h3>Products</h3>
        <div class="button-group">
          <button
            fmButton
            type="button"
            label="Clear"
            style="margin-right: 10px"
            (click)="handleClear()"
          ></button>
          <button
            fmButton
            [primary]="true"
            type="button"
            label="Submit"
            (click)="handleConfirmation('products')"
            [disabled]="!isAdmin"
          ></button>
        </div>
      </div>
      <br />
      <fm-table
        #tbl
        [columns]="cols"
        [value]="products"
        [enableTableEntryTotal]="true"
        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 100, 1000]"
      >
        <ng-template fmTemplate="caption">
          <div style="text-align: right">
            <input
              placeholder="Global Filter"
              style="width: auto"
              fmTextInput
              type="text"
              [(ngModel)]="globalFilterBinding"
              (input)="tbl.filterGlobal($any($event.target).value, 'contains')"
            />
          </div>
        </ng-template>
        <ng-template fmTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">{{ col.header }}</th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
              <input
                placeholder="{{ col.header }}"
                style="width: 100%"
                fmTextInput
                type="text"
                [(ngModel)]="inputBindings[col.field]"
                (input)="
                  tbl.filter($any($event.target).value, col.field, 'contains')
                "
              />
            </th>
          </tr>
        </ng-template>
        <ng-template fmTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" [style.width]="col.width">
              <ng-container
                *ngIf="
                  col.field === 'beg_mkt_sprd' || col.field === 'end_mkt_sprd';
                  else showData
                "
              >
                <input
                  type="number"
                  fmTextInput
                  [(ngModel)]="rowData[col.field]"
                  (ngModelChange)="onValueChange(rowData, col.field)"
                  [ngClass]="{
                    changed:
                      rowData.changedFields && rowData.changedFields[col.field]
                  }"
                  [disabled]="!isAdmin"
                  step="1"
                  onkeypress="return event.charCode >= 48 || event.charCode == 45 && event.charCode <= 57"
                />
              </ng-container>
              <ng-template #showData>
                {{ rowData[col.field] }}
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </fm-table>
    </fm-tabPanel>
  </fm-tabView>
</div>

<fm-confirmDialog
  acceptLabel="Confirm"
  key="confirm"
  [alignLeft]="true"
></fm-confirmDialog>

<fm-loading-spinner [isLoading]="isLoading"></fm-loading-spinner>
