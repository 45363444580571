import { Component, ViewChild } from '@angular/core';
import { ConfirmationService } from '@fm-ui-adk/components-3/common/services';
import { FmTableComponent } from '@fm-ui-adk/components-3/fm-table';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { RateEntryService } from 'src/app/services/rate-entry.service';

type TabConfig = {
  isOrganization: boolean;
  isView: boolean;
  isProduct: boolean;
  isUser: boolean;
};

@Component({
  selector: 'app-admin-entry',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  orgCols: any;
  orgGlobalFilterBinding: string;
  viewGlobalFilterBinding: string;
  productGlobalFilterBinding: string;
  userGlobalFilterBinding: string;
  isCardVisible = false;
  selectedOrg: any = {};
  organizations: object[] = [];
  isLoading: boolean = true;
  isReadMode: boolean = true;
  isEditMode: boolean = false;
  selectedComponents: { viewLabel: string; viewValue: string }[] = [];
  views: object[];
  index: object[];
  allViewsForOrg: { viewLabel: string; viewValue: string }[] = [];
  allViewsForProducts: { label: string; value: string }[] = [];
  allIndexForProducts: { label: string; value: string }[] = [];
  selectedViewsForDropdown: string[] = [];
  selectedViewForProductsDropdown: string[];
  isSubmit: boolean = false;
  viewCols: any;
  isOrganization: boolean = true;
  isView: boolean = false;
  isProduct: boolean = false;
  isUser: boolean = false;
  selectedView: any = {};
  products: object[];
  selectedProduct: any = {};
  productCols: any;
  cvtRadio: string;
  cvtRadiSelected: string;
  coreIndRadio: string;
  coreIndRadiSelected: string;
  selectedUser: any = {};
  users: object[];
  userCols: any;
  isAddMode: boolean = false;
  roles: string[] = [];
  isAdmin: boolean;
  userStatus: boolean;
  alert: any = [];
  uid: string;
  allOrganizationsForUsers: { label: string; value: string }[] = [];
  errorMessage: string = '';

  constructor(
    private adminService: AdminService,
    private rateEntryService: RateEntryService,
    private authService: AuthService,
    private confirmationService: ConfirmationService
  ) {}
  ngOnInit() {
    this.roles = this.authService.getRoles();
    this.uid = this.authService.getUid();
    this.isAdmin =
      this.roles.includes('SG-NV-ACPT-Trader') ||
      this.roles.includes('SG-NV-PROD-Trader');
    this.orgCols = [
      { field: 'org_name', header: 'Organization Name', width: '95%' },
      { field: 'modify', header: '', width: '5%' },
      { field: 'delete', header: '', width: '5%' },
    ];
    this.viewCols = [
      { field: 'vw_name', header: 'View Name', width: '95%' },
      { field: 'modify', header: '', width: '5%' },
      { field: 'delete', header: '', width: '5%' },
    ];
    this.productCols = [
      { field: 'term', header: 'Term', width: '23.75%' },
      {
        field: 'yld_maint',
        header: 'Yield Maintenance',
        width: '23.75%',
      },
      { field: 'index', header: 'Index', width: '23.75%' },
      { field: 'view', header: 'View', width: '23.75%' },
      { field: 'modify', header: '', width: '5%' },
      { field: 'delete', header: '', width: '5%' },
    ];
    this.userCols = [
      { field: 'full_name', header: 'User Name', width: '95%' },
      { field: 'modify', header: '', width: '5%' },
    ];
    this.handleGetAdmin();
  }

  showCard(rowData: any) {
    this.isCardVisible = true;
    this.isReadMode = true;
    this.isEditMode = false;
    this.isAddMode = false;
    this.isSubmit = false;
    if (this.isOrganization) {
      this.selectedOrg = rowData;
      this.selectedViewsForDropdown = rowData.views;
    } else if (this.isView) {
      this.selectedView = rowData;
    } else if (this.isProduct) {
      this.selectedProduct = rowData;
    } else if (this.isUser) {
      this.selectedUser = rowData;
    }
  }

  closeCard() {
    this.isCardVisible = false;
    this.isEditMode = false;
    this.isSubmit = false;
    this.isReadMode = false;
  }

  onModify(rowData: any) {
    if (this.isOrganization) {
      this.selectedOrg = rowData;
      this.selectedViewsForDropdown = rowData.views;
    } else if (this.isView) {
      this.selectedView = rowData;
    } else if (this.isProduct) {
      this.selectedProduct = rowData;
    } else if (this.isUser) {
      this.selectedUser = rowData;
    }
    this.isAddMode = false;
    this.isReadMode = false;
    this.isCardVisible = true;
    this.isEditMode = true;
    this.isSubmit = true;
  }

  onDelete(id: string) {
    let type: string = '';

    if (this.isOrganization) {
      type = 'organization';
    } else if (this.isView) {
      type = 'view';
    } else if (this.isProduct) {
      type = 'product';
    }

    if (type) {
      this.adminService.deleteAdminData(type, id).subscribe({
        next: () => {
          this.handleGetAdmin();
          this.handleSuccess('success');
        },
        error: (error) => this.handleError(error),
      });
    }
  }

  handleGetAdmin(): void {
    this.adminService.getAdminData().subscribe({
      next: (data) => {
        this.organizations = data && data.organizations;
        this.allOrganizationsForUsers = [
          { label: 'None', value: 'None' },
          ...this.organizations.map((org: any) => ({
            label: org.org_name,
            value: org.org_name,
          })),
        ];
        this.views = data && data.views;
        this.allViewsForOrg = this.views.map((view: any) => ({
          viewLabel: view.vw_name,
          viewValue: view.vw_name,
        }));
        this.allViewsForProducts = this.views.map((view: any) => ({
          label: view.vw_name,
          value: view.vw_name,
        }));
        this.index = data && data.index;
        this.allIndexForProducts = this.index.map((index: any) => ({
          label: index,
          value: index,
        }));
        this.products = data && data.products;
        this.users = data && data.users;
        this.userStatus = data && data.user_status === 'D' ? false : true;
      },
      error: (err) => console.error('error on GET admin api:', err),
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleConfirmation(): void {
    if (this.isOrganization && !this.checkSelectedOrg()) {
      return;
    }
    if (this.isView && !this.checkSelectedView()) {
      return;
    }
    if (this.isProduct && !this.checkSelectedProduct()) {
      return;
    }

    if (this.checkForDuplicateOrgName()) {
      return;
    }

    if (this.checkForDuplicateViewName()) {
      return;
    }

    this.showDialog('editAndAdd', null);
  }

  handleDeleteConfirmation(id: string): void {
    this.isAddMode = false;
    this.isEditMode = false;
    this.showDialog('delete', id);
  }

  onSubmit(): void {
    let type: string = '';
    let data: any;

    if (this.isOrganization) {
      this.selectedOrg.views = this.selectedViewsForDropdown;
      type = 'organization';
      data = this.selectedOrg;
    } else if (this.isView) {
      type = 'view';
      data = this.selectedView;
    } else if (this.isProduct) {
      type = 'product';
      data = this.selectedProduct;
    } else if (this.isUser) {
      type = 'user';
      data = { org: this.selectedUser.org, user_id: this.selectedUser.user_id };
    }

    if (type && data) {
      this.saveAdminData(type, data);
    }
  }

  saveAdminData(type: string, data: any[] | string): void {
    this.adminService
      .upsertAdminData(type, this.uid, data, this.isAddMode)
      .subscribe({
        next: () => {
          this.handleGetAdmin();
          this.handleSuccess('success');
        },
        error: (error) => this.handleError(error),
      });
  }

  handleTabChange(event: any): void {
    const tabName = event.originalEvent.target.innerText;
    const tabsConfigs: { [key: string]: TabConfig } = {
      Organizations: {
        isOrganization: true,
        isView: false,
        isProduct: false,
        isUser: false,
      },
      Views: {
        isOrganization: false,
        isView: true,
        isProduct: false,
        isUser: false,
      },
      Products: {
        isOrganization: false,
        isView: false,
        isProduct: true,
        isUser: false,
      },
      Users: {
        isOrganization: false,
        isView: false,
        isProduct: false,
        isUser: true,
      },
    };
    const selectedTab = tabsConfigs[tabName] || {};
    this.isOrganization = selectedTab.isOrganization || false;
    this.isView = selectedTab.isView || false;
    this.isProduct = selectedTab.isProduct || false;
    this.isUser = selectedTab.isUser || false;
    this.isCardVisible = false;
    this.errorMessage = '';
  }

  onAdd() {
    if (this.isOrganization) {
      this.selectedOrg = {};
      this.selectedViewsForDropdown = [];
    } else if (this.isView) {
      this.selectedView = {};
    } else if (this.isProduct) {
      this.selectedProduct = {};
    }
    this.isCardVisible = true;
    this.isAddMode = true;
    this.isEditMode = false;
    this.isReadMode = false;
    this.isSubmit = true;
  }

  handleUpdateStatus(userStatus: boolean): void {
    const data = userStatus === false ? 'D' : 'E';
    this.rateEntryService.putUpdateStatus(data).subscribe({
      next: () => {},
      error: () => {},
    });
  }

  handleSuccess(type: any): void {
    this.alert = [];
    this.alert.push({ type: type, message: type });
  }

  handleError(error: any): void {
    this.alert = [];
    this.alert.push({ type: 'error', message: error.error });
  }

  checkForDuplicateViewName(): boolean {
    if (this.isAddMode && this.isView) {
      const isViewNameDuplicate = this.views.some(
        (view: any) =>
          view.vw_name === this.selectedView.vw_name &&
          this.selectedView.vw_name.trim()
      );
      if (isViewNameDuplicate) {
        this.errorMessage = `${this.selectedView.vw_name} already exists`;
        return true;
      }
    }
    this.errorMessage = '';
    return false;
  }

  checkForDuplicateOrgName(): boolean {
    if (this.isAddMode && this.isOrganization) {
      const isOrgNameDuplicate = this.organizations.some(
        (org: any) =>
          org.org_name === this.selectedOrg.org_name &&
          this.selectedOrg.org_name.trim()
      );
      if (isOrgNameDuplicate) {
        this.errorMessage = `${this.selectedOrg.org_name} already exists`;
        return true;
      }
    }
    this.errorMessage = '';
    return false;
  }

  showDialog(mode: string, id: string | null) {
    let header: string = '';
    let message: string = '';
    let type: string = '';

    if (this.isOrganization) {
      type = 'organization';
    } else if (this.isView) {
      type = 'view';
    } else if (this.isProduct) {
      type = 'product';
    }

    if (this.isEditMode) {
      header = 'Are you sure you want to update these values?';
      message =
        'Click "Confirm" to submit the updated data values in the table and they will be updated for the corresponding records';
    } else if (this.isAddMode) {
      header = `Are you sure you want to add this new ${type}?`;
      message = `Click "Confirm" to add this new ${type} to the system along with the data attributes for this new ${type}`;
    } else if (mode === 'delete') {
      header = `Are you sure you want to delete this ${type}?`;
      message = `You are about to delete this ${type} and it's entire contents. Click "Confirm" to continue`;
    }
    this.confirmationService.confirm({
      header,
      message,
      accept: () => {
        if (mode === 'delete' && id) {
          this.onDelete(id);
        } else {
          this.onSubmit();
        }
      },
      reject: () => {
        console.log('Rejected');
      },
      key: 'confirm',
    });
  }

  checkSelectedOrg(): boolean {
    this.selectedOrg.views = this.selectedViewsForDropdown;
    if (
      this.selectedOrg &&
      this.selectedOrg.org_name &&
      this.selectedOrg.org_name.trim() !== ''
    ) {
      return true;
    }
    return false;
  }

  checkSelectedView(): boolean {
    if (
      this.selectedView &&
      this.selectedView.vw_name &&
      this.selectedView.vw_name.trim() !== '' &&
      this.selectedView.vw_title &&
      this.selectedView.vw_title.trim() !== '' &&
      this.selectedView.related_content &&
      this.selectedView.related_content.trim() !== ''
    ) {
      return true;
    }
    return false;
  }

  checkSelectedProduct(): boolean {
    if (
      this.selectedProduct &&
      this.selectedProduct.term &&
      !isNaN(Number(this.selectedProduct.term)) &&
      this.selectedProduct.view &&
      this.selectedProduct.view.trim() !== '' &&
      this.selectedProduct.yld_maint &&
      this.selectedProduct.yld_maint.trim() !== '' &&
      this.selectedProduct.index &&
      this.selectedProduct.index.trim() !== '' &&
      this.selectedProduct.description &&
      this.selectedProduct.description.trim() !== '' &&
      this.selectedProduct.core_ind &&
      this.selectedProduct.core_ind.trim() !== '' &&
      this.selectedProduct.cvt &&
      this.selectedProduct.cvt.trim() !== ''
    ) {
      return true;
    }
    return false;
  }
}
