import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FmComponentsModule } from '@fm-ui-adk/components-3/components_module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScreenComponent } from './modules/screen/screen.component';
import { SideNavComponent } from './modules/screen/side-nav/side-nav.component';
import { ContentComponent } from './modules/screen/content/content.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RateEntryComponent } from './modules/rate-entry/rate-entry.component';
import { AdminComponent } from './modules/admin/admin.component';

@NgModule({
  declarations: [
    AppComponent,
    ScreenComponent,
    SideNavComponent,
    ContentComponent,
    RateEntryComponent,
    AdminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FmComponentsModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
