import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  getAdminData(): Observable<any> {
    return this.http.get(window.location.origin + '/v1/apis/admin');
  }

  upsertAdminData(
    type: string,
    uid: string,
    data: any,
    isAddMode: boolean
  ): Observable<any> {
    return isAddMode
      ? this.http.post(
          window.location.origin + `/v1/apis/admin?type=${type}&user=${uid}`,
          data
        )
      : this.http.put(
          window.location.origin + `/v1/apis/admin?type=${type}&user=${uid}`,
          data
        );
  }

  deleteAdminData(type: string, id: string): Observable<any> {
    return this.http.delete(
      window.location.origin + `/v1/apis/admin?type=${type}&id=${id}`
    );
  }
}
