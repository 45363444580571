import { Component, Output, EventEmitter, Input } from '@angular/core';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  @Input() sideNavMenu: any = [];
  @Output() itemSelected = new EventEmitter<string>();
  selectedItem: any;

  ngOnInit() {}

  selectItem(item: any) {
    this.selectedItem = item;
    this.itemSelected.emit(item);
  }
}
